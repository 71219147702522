import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { SelectedProductRow } from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/productRow/SelectedProductRow'
import { CreateCollectionFormEntitieValue } from 'pages/collections/common/createCollectionSideModal/formUtils'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/selectedProductsSection/SelectedProductsSection.styled'

interface Props {
  selectedProducts: CreateCollectionFormEntitieValue[]
  handleRemoveVersion: (versionId: string) => void
}

export const SelectedProductsSection = ({ selectedProducts, handleRemoveVersion }: Props) => {
  const { t } = useTranslation(['collections'])

  return (
    <Flex direction="column" data-testid="selected-products-section">
      <S.SelectedProductsAccordion size="m" data-testid="selected-products-accordion">
        <WppTypography type="m-strong" slot="header">
          {t('collections|create_collection.products_step.products_selected', {
            count: selectedProducts.length,
          })}
        </WppTypography>
        <S.SelectedProductsWrapper direction="column" grow={1}>
          {!!selectedProducts.length ? (
            selectedProducts.map((product, index) => (
              <SelectedProductRow
                key={product.versionId}
                entity={product}
                isLastRow={index === selectedProducts.length - 1}
                handleRemoveVersion={handleRemoveVersion}
                data-testid="selected-product-row"
              />
            ))
          ) : (
            <S.NoSelectedProducts type="s-body" data-testid="no-selected-products">
              {t('collections|create_collection.products_step.no_products_are_selected')}
            </S.NoSelectedProducts>
          )}
        </S.SelectedProductsWrapper>
      </S.SelectedProductsAccordion>
    </Flex>
  )
}
