import { WppDivider } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { CreateCollectionFormEntitieValue } from 'pages/collections/common/createCollectionSideModal/formUtils'
import { ProductType } from 'types/products/enums'
import { Truncate } from 'ui-base/truncate/Truncate'

import * as S from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/productRow/ProductRow.styled'

interface Props {
  entity: CreateCollectionFormEntitieValue
  isLastRow: boolean
  handleRemoveVersion: (versionId: string) => void
}

export const SelectedProductRow = ({ entity, isLastRow, handleRemoveVersion }: Props) => {
  const { t } = useTranslation(['common'])
  const { shortDescription, productType, logoUrl, productName, versionId, versionName } = entity
  const isNoCodeProduct = productType === ProductType.NO_CODE_APPLICATION

  return (
    <>
      <S.ProductRowContent gap={8} align="center" justify="between" data-testid="selected-product-row">
        <S.ProductLogo src={logoUrl} size="s" variant="square" data-testid="product-logo" />

        <S.NameWrapper direction="column" grow={1}>
          <Truncate type="s-body">
            {productName} {!isNoCodeProduct && ` - ${versionName}`}
          </Truncate>
          <S.Description type="s-body">{shortDescription || '-'}</S.Description>
        </S.NameWrapper>
        <S.ActionButton onClick={() => handleRemoveVersion(versionId)} data-testid="remove-version-button">
          {t('common|remove')}
        </S.ActionButton>
      </S.ProductRowContent>

      {!isLastRow && <WppDivider />}
    </>
  )
}
