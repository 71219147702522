import {
  WppIconSearch,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { RefCallback, useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInfiniteProductsWithVersionsApi } from 'api/collections/queries/useInfiniteProductsWithVersionsApi'
import { Delays } from 'constants/delays'
import { PageSizes } from 'constants/pageSizes'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { ProductWithVersionsRow } from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/productRow/ProductWithVersionsRow'
import { ProductRowSkeleton } from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/productRowSkeleton/ProductRowSkeleton'
import { SelectedProductsSection } from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/selectedProductsSection/SelectedProductsSection'
import {
  CreateCollectionSideModalState,
  CREATE_COLLECTION_STEPS,
} from 'pages/collections/common/createCollectionSideModal/CreateCollectionSideModal'
import {
  CollectionFormProductWithVersionsDTO,
  CreateCollectionFormEntitieValue,
} from 'pages/collections/common/createCollectionSideModal/formUtils'
import { EmptyState } from 'ui-base/emptyState/EmptyState'
import { Flex } from 'ui-base/flex/Flex'
import { NoTableContentSvg } from 'ui-base/svgIcons/emptyState/assets/NoTableContentSvg'
import { NothingFoundSvg } from 'ui-base/svgIcons/emptyState/assets/NothingFoundSvg'

import * as S from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/ProductsSelectionStep.styled'

export enum CollectionSegmentedControlValue {
  SHARED = 'shared',
  CREATED = 'created',
}

interface Props {
  shared: boolean
  search: string
  isCreateCollectionModalOpen: boolean
  currentStep: number
  setState: (props: Partial<CreateCollectionSideModalState>) => void
}

export const ProductSelectionStep = ({ shared, search, isCreateCollectionModalOpen, currentStep, setState }: Props) => {
  const { t } = useTranslation(['collections', 'common', 'errors'])
  const { setValue, watch } = useFormContext()
  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const selectedProducts: CreateCollectionFormEntitieValue[] = watch('elements')
  const isFiltersApplied = !!shared
  const isSearchApplied = !!search.length
  const isFiltersOrSearchApplied = !!search || !!shared

  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [setLoadMoreRef])

  const {
    data: productsWithVersions,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteProductsWithVersionsApi({
    params: {
      searchPhrase: search,
      shared,
    },
    enabled: isCreateCollectionModalOpen && currentStep === CREATE_COLLECTION_STEPS.PRODUCTS,
  })
  const isProductsWithVersionsLoading = isLoading || isFetchingNextPage

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  })

  const mappedProductsWithVersions = useMemo(() => {
    return productsWithVersions?.reduce((acc, curr) => {
      let hasSelectedVersions = false

      const mappedVersions = curr.versions.map(version => {
        const isCurrVersionSelected = selectedProducts.some(selectedProduct => selectedProduct.versionId === version.id)
        hasSelectedVersions = isCurrVersionSelected || hasSelectedVersions
        return {
          ...version,
          isSelected: isCurrVersionSelected,
        }
      })

      return [
        ...acc,
        {
          ...curr,
          hasSelectedVersions,
          versions: mappedVersions,
        },
      ]
    }, [] as CollectionFormProductWithVersionsDTO[])
  }, [productsWithVersions, selectedProducts])
  const isEmpty = !isLoading && !mappedProductsWithVersions.length

  const setSearchDebounced = useDebounceFn((search: string) => {
    setState({ search })
  }, Delays.Search)

  const handleAddVersion = useCallback(
    (entity: CreateCollectionFormEntitieValue) => {
      setValue('elements', [entity, ...selectedProducts], { shouldValidate: true })
    },
    [setValue, selectedProducts],
  )

  const handleRemoveVersion = useCallback(
    (versionId: string) => {
      setValue(
        'elements',
        [...selectedProducts].filter(version => version.versionId !== versionId),
        { shouldValidate: true },
      )
    },
    [setValue, selectedProducts],
  )

  const handleVersionsSelection = useCallback(
    (elements: CreateCollectionFormEntitieValue[]) => {
      //remove versions
      const versionIdsToRemove = new Set(elements.filter(entity => !entity.isSelected).map(entity => entity.versionId))
      const filteredSelectedVersions = selectedProducts.filter(val => !versionIdsToRemove.has(val.versionId))

      //add versions (without duplications)
      const selectedProductIds = new Set(selectedProducts.map(product => product.versionId))
      const versionsToAdd = elements.filter(item => item.isSelected && !selectedProductIds.has(item.versionId))

      setValue('elements', [...versionsToAdd, ...filteredSelectedVersions], { shouldValidate: true })
    },
    [setValue, selectedProducts],
  )

  return (
    <S.StepWrapper direction="column" gap={24} data-testid="select-products-second-step">
      <SelectedProductsSection selectedProducts={selectedProducts} handleRemoveVersion={handleRemoveVersion} />

      <Flex direction="column" gap={12} data-testid="products-with-versions-section">
        <WppTypography type="m-strong" data-testid="products-with-versions-section-title">
          {t('collections|create_collection.products_step.my_products')}
        </WppTypography>

        <Flex align="center" gap={16}>
          <S.SearchInput
            onWppChange={({ detail }) => setSearchDebounced(detail?.value || '')}
            placeholder={t('common|search_product_by_name')}
            value={search}
            type="search"
            size="s"
            data-testid="products-search-input"
          >
            <WppIconSearch slot="icon-start" aria-label="Search icon" onClick={() => setSearchDebounced('')} />
          </S.SearchInput>

          <WppSegmentedControl
            value={shared ? CollectionSegmentedControlValue.SHARED : CollectionSegmentedControlValue.CREATED}
            size="s"
            onWppChange={({ detail }) =>
              setState({ shared: detail.value === CollectionSegmentedControlValue.CREATED ? false : true })
            }
            data-testid="products-ownership-segmented-control"
          >
            <WppSegmentedControlItem value={CollectionSegmentedControlValue.CREATED}>
              {t('collections|create_collection.products_step.filters.created')}
            </WppSegmentedControlItem>
            <WppSegmentedControlItem value={CollectionSegmentedControlValue.SHARED}>
              {t('collections|create_collection.products_step.filters.shared')}
            </WppSegmentedControlItem>
          </WppSegmentedControl>
        </Flex>

        <S.ProductsWithVersionsListWrapper direction="column">
          {isEmpty && (
            <>
              <EmptyState
                message={{
                  title: t(
                    `collections|create_collection.products_step.${
                      isFiltersOrSearchApplied ? 'nothing_found.title' : 'empty_state.title'
                    }`,
                  ),
                  ...(isFiltersOrSearchApplied && {
                    subtitle: t(
                      `collections|create_collection.products_step.nothing_found.subtitle_${
                        isSearchApplied && isFiltersApplied
                          ? 'filters_search_applied'
                          : isSearchApplied
                          ? 'search_applied'
                          : 'filters_applied'
                      }`,
                    ),
                  }),
                }}
                image={isFiltersOrSearchApplied ? <NothingFoundSvg /> : <NoTableContentSvg />}
                margin="120px 0"
                showImage
                data-testid={isFiltersOrSearchApplied ? 'products-nothing-found' : 'empty-products-list'}
              />
            </>
          )}
          {mappedProductsWithVersions.map(product => (
            <ProductWithVersionsRow
              productWithVersions={product}
              handleAddVersion={handleAddVersion}
              handleRemoveVersion={handleRemoveVersion}
              handleVersionsSelection={handleVersionsSelection}
              key={product.id}
            />
          ))}
          {isProductsWithVersionsLoading &&
            Array.from({ length: PageSizes.PAGINATED_PRODUCTS_WITH_VERSIONS }).map((_, index) => (
              <Flex direction="column" key={index}>
                <ProductRowSkeleton />
              </Flex>
            ))}
          <div ref={setRef} />
        </S.ProductsWithVersionsListWrapper>
      </Flex>
    </S.StepWrapper>
  )
}
